.page {
  --card-title-color: #333333ff;
  --color-primary: white;
  --border-color: 6px solid #33333354;
  --glass-color: #ffffff42;
  --border-glass: 1px solid #ffffff96;
  --border-glass-right-bottom: 1px solid #ffffff03;
  --black-logo-color: #333333ff;
  --white-logo-color: #ff5d19;

  height: max-content;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  overflow: hidden;
  background: linear-gradient(to bottom right, #e6e6e6 31%, #cfcfcf 100%);
}
