.deliveryBox {
  margin-top: 50px;
  width: 90%;
  max-width: 2190px;
  -webkit-backdrop-filter: blur(8.7px);
  backdrop-filter: blur(171.7px);
  border-radius: 60px;
  display: flex;
  justify-content: space-evenly;
  height: max-content;
  box-sizing: border-box;
  padding: 50px;
  /* box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 32%); */
  background-color: var(--glass-color);
  border: var(--border-glass);
  border-right: var(--border-glass-right-bottom);
  border-bottom: var(--border-glass-right-bottom);
  background: radial-gradient(30% 40% at 65% 70%, #ffb12d 1%, #c058fe00 100%),
    radial-gradient(30% 40% at 68% 45%, #ff392c 1%, #ff392c00 100%),
    radial-gradient(30% 40% at 38% 25%, #e34b19 1%, #ff8a3500 100%),
    radial-gradient(60% 60% at 37% 68%, #ff823a 1%, #ff7dfb00 100%);
}

.deliveryBoxIcon {
  max-width: 400px;
  margin-top: 10px;
  width: 30%;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
  animation: bounce-top 3.2s ease 0s infinite alternate none;
}

.deliveryTextBox {
  height: max-content;
  width: max-content;
  margin-left: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.deliveryTitle {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
  color: var(--black-logo-color);
  margin: 0;
}
.deliverySubTitle {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 20px 0 0 0;
}
.deliveryText {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--black-logo-color);
  margin: 10px 0 0 0;
  text-align: center;
  width: 500px;
  height: max-content;
}
.deliveryText:hover {
  font-size: 20px;
  transition: font-size 0.5s;
}

@media screen and (min-width: 280px) and (max-width: 850px) {
  .deliveryBox {
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    background: radial-gradient(30% 40% at 65% 70%, #ffb12d 1%, #c058fe00 100%),
      radial-gradient(30% 40% at 68% 45%, #ff392c 1%, #ff8f2c36 100%),
      radial-gradient(30% 40% at 38% 25%, #e34b19 1%, #ff8a3500 100%),
      radial-gradient(60% 60% at 37% 68%, #ff823a 1%, #ff7dfb00 100%);
  }
  .deliveryTextBox {
    width: 100%;
  }
  .deliveryTitle {
    font-size: 25px;
  }
  .deliverySubTitle {
    font-size: 20px;
    margin: 10px 0 0 0;
  }
  .deliveryText {
    width: 100%;
  }
  .deliveryBoxIcon {
    width: 60%;
    margin-top: 30px;
  }
}

@keyframes bounce-top {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-24px);
  }
  55% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(-12px);
  }
  75% {
    transform: translateY(0);
  }
  82% {
    transform: translateY(-6px);
  }
  87% {
    transform: translateY(0);
  }
  93% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}
