@font-face {
  font-family: "custom1";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/junegull\ rg.otf") format("woff2"),
    url("../fonts/junegull\ rg.otf") format("woff");
}

@font-face {
  font-family: "custom2";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/junegull\ rg.ttf") format("woff2"),
    url("../fonts/junegull\ rg.ttf") format("woff");
}

@font-face {
  font-family: "comforta";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Comfortaa-Medium.woff2") format("woff2"),
    url("../fonts/Comfortaa-Medium.woff")format("woff");
}

