.stringRun {
  width: 100%;
  height: min-content;
  max-height: min-content;
  margin-top: 25px;
}
.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  user-select: none;
  gap: 20px;
}
.items-wrap:before,
.items-wrap:after {
  content: "";
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.items-wrap:before {
  left: 0;
}
.items-wrap:after {
  right: 0;
}
.items {
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  justify-content: space-around;
  min-width: 100%;
}
.item {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-logo-color);
  transition: all 0.1s ease-in-out;
  max-height: 0;
}

.item:nth-child(odd){
  color: var(--black-logo-color);
}

.item:hover {
  transform: scale(1.05);
}

.marquee {
  animation: scroll 20s linear infinite;
}
.reverce {
  animation-direction: reverse;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

@media screen and (min-width: 550px) and (max-width: 770px) {
  .item {
    font-size: 30px;
  }
  .stringRun {
    top: 20%;
  }
}

@media screen and (min-width: 280px) and (max-width: 549px) {
    .item {
      font-size: 20px;
    }
    .stringRun {
        top: 25%;
      }
  }
