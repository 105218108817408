.ProductsPage {
  width: 100%;
  padding: 0;
  margin: 50px 0px 50px 0px;
  max-width: 2190px;
  height: max-content;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-template-rows: repeat(2, max-content);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.article-card {
  height: min-content;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  font-family: custom1, Helvetica, sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 300ms;
  margin: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  /* border: 6px solid #aaaaaa12; */
}

.article-card:hover {
  transform: translateY(-10px);
  box-shadow: none;
  border: 1px solid #ffffff;
}

.article-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-card .content {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 20px 20px;
  height: auto;
  bottom: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
}

.article-card .date,
.article-card .title {
  margin: 0;
}

.article-card .title {
  font-size: 23px;
  color: white;
}

.pulseIcon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../../images/pulseIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  animation: pulse-zoomin 1s linear 0s infinite normal forwards;
}

@media screen and (min-width: 721px) and (max-width: 1320px) {
  .ProductsPage {
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(2, max-content);
    grid-gap: 44px;
    justify-content: center;
  }
}

@media screen and (min-width: 280px) and (max-width: 720px) {
  .ProductsPage {
    grid-template-columns: 90%;
    grid-template-rows: repeat(4, max-content);
    grid-gap: 20px;
  }
  .article-card {
    margin: 0;
  }
  .article-card:hover {
    transform: scale(0.9);
    box-shadow: none;
  }
}

@keyframes pulse-zoomin {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
  }
}
