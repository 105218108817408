.productCardList {
  width: 90%;
  max-width: 2190px;
  height: max-content;
  position: relative;
  z-index: 1;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.productCardList-navigation {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  border-radius: 13px;
  box-sizing: border-box;
}
.navigation-title {
  width: 100%;
  margin: 1px;
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--black-logo-color);
  margin: 0;
  padding: 4px 0 4px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  -webkit-tap-highlight-color: transparent;
}

.navigation-title:hover {
  color: #e6e6e6;
  background-color: #333333;
}
.productGrid {
  width: 100%;
  height: max-content;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 50px;
  justify-items: center;
  border-radius: 13px;
  box-sizing: border-box;
}

@media screen and (min-width: 751px) and (max-width: 900px) {
  .productGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 551px) and (max-width: 750px) {
  .productGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 280px) and (max-width: 551px) {
  .productGrid {
    grid-template-columns: 1fr;
  }
  .productCardList-navigation {
    flex-direction: column;
    align-content: space-evenly;
    padding: 10px 0 10px 0;
  }
}
