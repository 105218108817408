.logoBox {
  height: 237px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;

  background-color: #eea3a3;
  background-image: radial-gradient(
      30% 40% at 65% 70%,
      #ffb12d 1%,
      #c058fe00 100%
    ),
    radial-gradient(30% 40% at 68% 45%, #ff392c 1%, #ff392c00 100%),
    radial-gradient(30% 40% at 38% 25%, #e34b19 1%, #ff8a3500 100%),
    radial-gradient(60% 60% at 37% 68%, #ff823a 1%, #ff7dfb00 100%);
  background-size: 240% 240%;
  animation: gradient-animation 20s ease infinite;
  padding: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 6px 5px -1px rgba(34, 60, 80, 0.67);
  -moz-box-shadow: 0px 6px 5px -1px rgba(34, 60, 80, 0.67);
  box-shadow: 0px 6px 5px -1px rgba(34, 60, 80, 0.67);
}

.logoMain {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
}
.miniLogo {
  position: fixed;
  top: 10px;
  left: 10px;
  background-image: url(../../images/logoFooter.png);
  width: 5%;
  max-width: 100px;
  height: 25%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 3;
  transition: opacity 0.5s;
}
.contentBox {
  height: max-content;
  max-width: 2190px;
  /* width: 90%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 150px 20px 0 20px;
  overflow: hidden;
}

.helloTitle {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  color: var(--black-logo-color);
  margin: 0;
  width: 100%;
  text-align: center;
}
.helloTitleMessage {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--black-logo-color);
  margin: 100px 0 0 0;
  width: 100%;
  text-align: center;
}

.descriptionBrand {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: var(--black-logo-color);
  width: 100%;
  margin: 37px 0 0 0;
  text-align: justify;
}
/* .descriptionBrand:hover {
  font-size: 20px;
  text-align: center;
  transition: font-size 0.5s;
} */
.mainLine {
  margin-top: 50px;
  width: 50%;
  height: 3px;
  background-color: #33333366;
  border-radius: 10px;
}
@media screen and (min-width: 651px) and (max-width: 750px) {
  .helloTitle {
    font-size: 30px;
  }
  .descriptionBrand {
    font-size: 12px;
  }
}

@media screen and (min-width: 280px) and (max-width: 650px) {
  .logoBox {
    height: 150px;
    padding: 10px 10px 10px 0;
  }
  .contentBox {
    /* padding-right: 10px; */
    margin: 50px 0 0 0;
    width: 100%;
    align-items: start;
  }
  .helloTitle {
    font-size: 20px;
    text-align: start;
  }
  .helloTitleMessage {
    font-size: 15px;
    margin-top: 60px;
    text-align: end;
  }
  .descriptionBrand {
    font-size: 12px;
    margin-top: 20px;
    /* text-align: start; */
  }
  .mainLine {
    width: 70%;
    border-radius: 0 10px 10px 0;
  }
  .miniLogo {
    width: 15%;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
