.swiperRoad {
  width: 100% !important;
  height: max-content !important;
  position: relative !important;
  padding: 20px 0 20px 0;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiperCardRoad {
  width: 200px !important;
  height: 200px !important;
  border-radius: 10%;
  transition: all 0.1s ease-in-out;
}
.swiperCardRoad:hover {
  z-index: 1;
  transform: scale(1.2);
}

@media screen and (min-width: 280px) and (max-width: 650px) {
  .swiperCardRoad {
    width: 150px !important;
    height: 150px !important;
  }
}
