.buyListBoxPage {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.buyListBox {
  padding: 10px;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  width: 90%;
  max-width: 600px;
  height: max-content;
  z-index: 3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--glass-color);
  border: var(--border-glass);
  border-right: var(--border-glass-right-bottom);
  border-bottom: var(--border-glass-right-bottom);
}
.buyIntruction {
  width: 95%;
  margin: 0;
  height: max-content;
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--black-logo-color);
  margin: 0;
  text-align: center;
}
.buyList {
  width: 100%;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.buyListContact {
  width: 50%;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.buyListContactImg {
  width: 60%;
  height: max-content;
  background-repeat: no-repeat;
  background-size: contain;
}
.buyListContactLink {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  background-color: var(--black-logo-color);
  color: #e6e6e6;

  width: 60%;
  height: max-content;
  padding: 10px;
  text-align: center;

  box-sizing: border-box;
  border-radius: 10px;
  text-decoration: none;
  margin-top: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  animation: scale-up-center 1.2s ease-in 0s infinite alternate-reverse none;
}

.buyListContact:hover .buyListContactLink {
  background-color: var(--white-logo-color);
  color: var(--black-logo-color);
}

@media screen and (min-width: 280px) and (max-width: 620px) {
  .buyIntruction {
    font-size: 15px;
  }
  .buyListContactLink {
    font-size: 12px;
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
