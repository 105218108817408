.productGrid-cardItem {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.swiper {
  width: 100%;
  height: max-content;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background: white !important;
}

.swiper-button-next {
  color: white !important;
}
.swiper-button-prev {
  color: white !important;
}
.productGrid-cardItem-img {
  width: 100%;
  height: max-content;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 15px;
}

.productGrid-cardItem-card-description {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 15px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
}

.descriptionProduct {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--card-title-color);
  margin: 0;
  height: max-content;
  text-align: center;
  background-color: var(--color-primary);
  border-radius: 15px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.descriptionProduct:hover {
  background-color: var(--white-logo-color);
}
.numberOfPieces {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--card-title-color);
  margin: 0;
  height: max-content;
  text-align: center;
  background-color: var(--color-primary);
  border-radius: 15px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  animation: change-color 2s linear 0s infinite normal;
}
.productGrid-cardItem-card-title {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: var(--card-title-color);
  margin: 0;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
}
.productGrid-cardItem-card-price {
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: var(--card-title-color);
  margin: 0;
  text-align: center;
  height: max-content;
}
.productGrid-cardItem-card-price:after {
  content: " \20BD";
}

.elementBuy {
  width: 100%;
  height: max-content;
  background-color: var(--color-primary);
  border-radius: 15px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  font-family: "custom1", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--card-title-color);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.productGrid-cardItem:hover .elementBuy {
  background-color: var(--white-logo-color);
}
.descriptionCardTitle {
  width: 100%;
  margin: 0;
  height: max-content;
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--card-title-color);
  margin: 0;
  text-align: center;
}
.swiper-wrapper {
  position: relative !important;
}

.loader {
  width: 100%;
  height: 200px;
  display: inline-block;
  box-sizing: border-box;
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 2;
  backdrop-filter: blur(8px);
  /* background-color: blue; */
}
.loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
  z-index: 2;
}

@media screen and (min-width: 280px) and (max-width: 551px) {
  .productGrid-cardItem-card-title {
    height: max-content;
    display: block;
    text-align: center;
  }
  .loader {
    height: 350px;
  }
}

@keyframes change-color {
  0% {
    background-color: var(--color-primary);
  }
  50% {
    background-color: var(--white-logo-color);
  }
  100% {
    background-color: var(--color-primary);
  }
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
