.footerBlock {
  width: max-content;
  padding: 45px 100px 45px 100px;
  margin: 50px 20px 200px 20px;
  max-width: 1280px;
  height: max-content;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--glass-color);
  border: var(--border-glass);
  border-right: var(--border-glass-right-bottom);
  border-bottom: var(--border-glass-right-bottom);
  z-index: 1;
}

.footerLogo {
  width: 100px;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 15px;
}
.footerTitle {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--black-logo-color);
  margin: 5px;
  text-decoration: none;
}
.footerTitle:last-child {
  margin: 20px 0 0 0;
  background-color: rgb(51 51 51);
  padding: 10px;
  border-radius: 10px;
  color: white;
  animation: pulse-zoomin-1 1s linear 0s infinite normal forwards;
}

@media screen and (min-width: 280px) and (max-width: 621px) {
  .footerBlock {
    padding: 20px 20px 20px 20px;
    margin: 50px 0 0 0px;
    border-radius: 0;
    width: 100%;
  }
  .footerLogo {
    width: 50px;
  }
  .footerTitle {
    font-size: 10px;
  }
}

@keyframes pulse-zoomin-1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
