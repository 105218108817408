.infoBox {
  width: 90%;
  max-width: 1280px;
  height: max-content;
  position: fixed;
  bottom: 0;
  border-radius: 50px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  background-color: #ffffff03;
  -webkit-backdrop-filter: blur(8.7px);
  backdrop-filter: blur(8.7px);
  margin-bottom: 10px;
  z-index: 3;
  background-color: var(--glass-color);
  border: var(--border-glass);
  border-right: var(--border-glass-right-bottom);
  border-bottom: var(--border-glass-right-bottom);
}
.contactList {
  height: 95%;
  width: max-content;
  max-width: 95%;
  display: flex;
  justify-content: space-between;
  border-radius: 22px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.linkIcon {
  width: 131px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  margin: 0 3px 0 3px;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 10px;
  background-color: #aaaaaa12;
}
.linkIcon:hover {
  border: 1px solid #ffffff;
}

.linkIconColorPulse {
  background: var(--color-gradient);
  background-size: var(--backgroundSize-gradient);
  animation: var(--animation-gradient);
}

.linkIconTitle {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--black-logo-color);
  margin: 0;
  text-align: center;
  text-decoration: none;
}

.linkIcon_img {
  width: 40px;
  height: 40px;
  max-height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.linkIcon:hover {
  transform: translateY(-5px);
}

.mailBox {
  display: flex;
  width: min-content;
  height: max-content;
  align-items: center;
}
.mailBox:hover {
  transform: scale(1.1);
  background-color: rgb(51 51 51);
    border-radius: 10px;
    color: white;
}

.mailTitle {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: var(--black-logo-color);
  margin: 0;
  padding: 10px;
  text-decoration: none;
}
.mailBox:hover .mailTitle {
    color: white;
}

.productCatalogTitle {
  font-family: "comforta", "Sans-serif", "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: var(--black-logo-color);
  margin: 0;
  text-align: center;
}

.mobileLinkBox {
  display: none;
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: max-content;
  height: max-content;
  padding: 10px;
  background-color: #f0dec92b;
  border-radius: 20px;
  backdrop-filter: blur(8.7px);
  z-index: 3;
}
.mobileNavigateButton {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: max-content;
  height: max-content;
  padding: 10px;
  background: var(--color-gradient);
  background-size: var(--backgroundSize-gradient);
  animation: var(--animation-gradient);
  border-radius: 20px;
  backdrop-filter: blur(8.7px);
  z-index: 3;
}

.mobileLinkBox-image {
  display: block;
  background-image: url(../../images/mobileLinkBlock.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
.mobileLinkBox:hover {
  transform: translateY(-5px);
}

.linkIcon_img-mobile {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
.linkIcon_img-mobile:hover {
  transform: translateY(-5px);
}

.mobileLinkBox-open {
  display: none;
  position: fixed;
  flex-direction: column;
  align-items: center;
  bottom: 100px;
  width: 70%;
  height: max-content;
  padding: 10px;
  box-sizing: border-box;
  z-index: 3;
  border-radius: 25px;
  -webkit-backdrop-filter: blur(8.7px);
  backdrop-filter: blur(8.7px);
  background-color: var(--glass-color);
  border: var(--border-glass);
  border-right: var(--border-glass-right-bottom);
  border-bottom: var(--border-glass-right-bottom);
}
.mobileLinkBox-open-icon-box {
  width: 90%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileLinkBox-linkIcon {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 10px;
  border-radius: 15px;
  -webkit-tap-highlight-color: transparent;
}

.mobileLinkBox-linkIcon:hover {
  border: 1px solid #ffffff;
}

.iconClose {
  width: 100%;
  height: 45px;
  background-image: url(../../images/closeIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  align-content: center;
  border-radius: 15px;
  background-position: center;
  margin-top: 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.iconClose:hover {
  border: 1px solid #ffffff;
}

@media screen and (min-width: 280px) and (max-width: 949px) {
  .infoBox {
    display: none;
  }
  .mobileLinkBox {
    display: block;
  }
  .mobileNavigateButton {
    display: block;
  }
}
